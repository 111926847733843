import api from './index'

interface IVendor {
  id?: number
  vendorId?: number
  name?: string
}

export interface IVendorGroup {
  id: number
  name: string
  englishName: string
  representativeName: string
  vendors: IVendor[]
  businessNumber: string
  channel: string
  englishChannel: string
  brand: string
  grade: string
  SLPNAME: string
  email: string
  accountBalance: number
  virtualAccount: string
  creditLimit: string
  paymentTerm: string
  businessCategory: string
  code: string
  largeCategory: string
  mediumCategory: string
  smallCategory: string
  enabled: boolean
  memo: string
  accountId: string
  address: string
  tel: string
  phone: string
  status: boolean
  createdAt: string
  updatedAt: string
}

export interface IPostVendorGroups
  extends Omit<IVendorGroup, 'id' | 'memo' | 'accountId' | 'createdAt' | 'updatedAt'> {}

export interface IPatchVendorGroups extends IPostVendorGroups {}

interface IGetVendorGroupsParams {
  start: number
  perPage: number
  search?: string
  status?: boolean
}

export async function postVendorGroup(data: IPostVendorGroups): Promise<{id: number}> {
  try {
    const res = await api.post(`/vendorGroups`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVendorGroups(
  params: IGetVendorGroupsParams = {start: 0, perPage: 1000}
): Promise<{data: IVendorGroup[]; total: number}> {
  try {
    const res = await api.get(`/vendorGroups`, {params})
    return res.data
  } catch (e) {
    throw e
  }
}

export async function getVendorGroup(id: number): Promise<IVendorGroup> {
  try {
    const res = await api.get(`/vendorGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function patchVendorGroup(id: number, data: IPatchVendorGroups): Promise<{id: number}> {
  try {
    const res = await api.patch(`/vendorGroups/${id}`, data)
    return res.data
  } catch (e) {
    throw e
  }
}

export async function deleteVendorGroup(id: number): Promise<void> {
  try {
    const res = await api.delete(`/vendorGroups/${id}`)
    return res.data
  } catch (e) {
    throw e
  }
}
