import React, {useContext, useRef, useState, useEffect} from 'react'
import BasicTable from '../../../../components/common/BasicTable'
import withPageContext from '../../../../hocs/withPageContext'
import dayjs from 'dayjs'
import PageContext from '../../../../contexts/PageContext'
import {ActionType, BasicTableModalRef} from '../../../../components/common/BasicTableModal'
import VendorModal from './components/VendorModal'
import {getVendors, patchVendorPassword} from '../../../../api/vendors'
import {Button, Form, Input, Modal, Tag, message} from 'antd'
import {getVendorGroups, IVendorGroup} from '../../../../api/vendor-groups'
import {useForm} from 'antd/es/form/Form'
import XLSX from 'xlsx-js-style'

function Users() {
  const [vendorGroups, setVendorGroups] = useState<IVendorGroup[]>([])
  const {state, apiHandler} = useContext(PageContext)
  const [modalActions, setModalActions] = useState<ActionType[]>(['show'])
  const modalRef = useRef<BasicTableModalRef>(null)
  const [trackingModal, setTrackingModal] = useState<number>(-1)
  const [passwordForm] = useForm()
  const [excelDownloadLoading, setExcelDownloadLoading] = useState(false)

  function ascending(a, b) {
    var a = a.name.toString()
    var b = b.name.toString()
    return a.localeCompare(b)
  }

  const columns = [
    {key: 'id', dataIndex: 'id', title: '번호'},
    {
      dataIndex: 'brands',
      title: '브랜드',
      render: (brands) =>
        !!brands.length && brands.map((brand) => <Tag color={process.env.REACT_APP_THEME_COLOR}>{brand.name}</Tag>)
    },
    {key: 'name', dataIndex: 'name', title: '이름'},
    {dataIndex: 'accountId', title: 'ID'},
    {dataIndex: 'address', title: '주소'},
    {dataIndex: 'tel', title: '전화번호'},
    {dataIndex: 'phone', title: '휴대폰 번호'},
    {
      key: 'groupId',
      dataIndex: ['group', 'name'],
      title: '그룹',
      filters: vendorGroups.sort(ascending).map((vendorGroup) => ({text: vendorGroup.name, value: vendorGroup.id})),
      filterMultiple: false,
      filterSearch: true
    },
    {dataIndex: ['priceGroup', 'name'], title: '판매가 그룹'},
    {dataIndex: 'status', title: '상태'},
    {dataIndex: 'manager', title: '담당자'},
    {
      dataIndex: 'id',
      title: '비밀번호 초기화',
      render: (id) => (
        <Button
          size="small"
          onClick={(e) => {
            e.stopPropagation()
            setTrackingModal(id)
          }}
        >
          비밀번호 초기화
        </Button>
      )
    },
    {
      title: '등록일',
      dataIndex: 'createdAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    },
    {
      title: '수정일',
      dataIndex: 'updatedAt',
      render: (text: string) => <>{dayjs(text).format('YYYY-MM-DD')}</>
    }
  ]

  function showTableModal(e, data?, index?) {
    if (modalRef && modalRef.current) {
      if (data) modalRef.current.showModal({type: 'show', record: data})
      else modalRef.current.showModal({type: 'add'})
    }
  }

  async function onModalAction() {
    await apiHandler(state.params)
  }

  useEffect(() => {
    ;(async function () {
      await handleGetVendorGroups()
    })()
  }, [])

  useEffect(() => {
    if (trackingModal === -1) {
      passwordForm.resetFields()
      onModalAction()
    }
  }, [trackingModal])

  async function handleGetVendorGroups() {
    let i = 0
    let groups: any = []

    while (1) {
      const {data} = await getVendorGroups({status: true, start: i * 100, perPage: 100})
      groups = [...groups, ...data]
      if (!data.length) break
      i++
    }

    setVendorGroups(groups)
  }

  async function handleResetPassword(values) {
    if (!values.password) Modal.error({content: '새로운 비밀번호를 입력해주세요.'})
    else
      try {
        await patchVendorPassword(trackingModal, values)
        message.success('성공적으로 재설정되었습니다.')
        setTrackingModal(-1)
      } catch (e: any) {
        message.error(`재설정 도중 오류가 발생했습니다. (${e.response.status} : ${e.response.data.message})`)
      }
  }

  async function handleExcelDownload() {
    const selectedUsers: any = []

    setExcelDownloadLoading(true)

    const confirmDownload = confirm(
      '전체 데이터를 엑셀로 다운로드 하시겠습니까? 대량 데이터의 경우 시간이 소요될 수 있습니다.'
    )
    if (!confirmDownload) return

    let i = 0
    try {
      while (true) {
        const {data}: any = await getVendors({start: 100 * i, perPage: 100})
        if (data.length === 0) break
        selectedUsers.push(...data)
        i++
      }
    } catch (e) {
      message.error('데이터를 불러오는 중 오류가 발생했습니다.')
      setExcelDownloadLoading(false)
      return
    }

    const workbook = XLSX.utils.book_new()
    const worksheetData = selectedUsers.map((user) => [
      user.id,
      user.name,
      user.accountId,
      user.address,
      user.tel,
      user.phone,
      user.group?.name,
      user.priceGroup?.name,
      user.status,
      user.manager,
      dayjs(user.createdAt).format('YYYY-MM-DD'),
      dayjs(user.updatedAt).format('YYYY-MM-DD')
    ])

    const headers = [
      '번호',
      '이름',
      'ID',
      '주소',
      '전화번호',
      '휴대폰 번호',
      '그룹',
      '판매가 그룹',
      '상태',
      '담당자',
      '등록일',
      '수정일'
    ]

    worksheetData.unshift(headers)

    const worksheet = XLSX.utils.aoa_to_sheet(worksheetData)

    // 스타일링 추가
    worksheet['!cols'] = headers.map(() => ({wpx: 120})) // 열 너비 설정

    XLSX.utils.book_append_sheet(workbook, worksheet, '사용자 데이터')
    XLSX.writeFile(workbook, `Users_${dayjs().format('YYYYMMDDHHmmss')}.xlsx`)

    setExcelDownloadLoading(false)
  }

  return (
    <>
      <BasicTable
        showSearch
        rowKey="id"
        columns={columns}
        scroll={{x: 'auto'}}
        topButtons={[
          <Button key="add" type="primary" shape="round" onClick={(e) => showTableModal(e)}>
            업체 등록
          </Button>,
          <Button
            key="download"
            type="primary"
            shape="round"
            loading={excelDownloadLoading}
            onClick={handleExcelDownload}
          >
            전체 항목 엑셀 다운로드
          </Button>
        ]}
        loading={state.loading}
        dataSource={state.data.data}
        total={state.data.total}
        onRow={(record, rowIndex) => ({
          onClick: (e) => {
            setModalActions(['show', 'delete', 'edit'])
            showTableModal(e, record, rowIndex)
          }
        })}
      />
      <VendorModal actions={modalActions} ref={modalRef} onAction={onModalAction} />
      <Modal
        footer={false}
        title={`${trackingModal}번 ${state.data.data?.find(({id}) => id === trackingModal)?.name} 업체 비밀번호 재설정`}
        open={trackingModal !== -1}
        onCancel={() => setTrackingModal(-1)}
      >
        <Form form={passwordForm}>
          <Form.Item label="새 비밀번호" name="password">
            <Input.Password />
          </Form.Item>
          <div style={{display: 'flex', justifyContent: 'end'}}>
            <Button type="primary" onClick={() => handleResetPassword(passwordForm.getFieldsValue())}>
              재설정
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

export default withPageContext(Users, getVendors)
